export default {
  primaryColor: '#004C7C',
  primaryColorDarker: '#004C7C',
  primaryColorLight: '#004C7C',
  customColorText: '#fff',
  titleText: '#07203C', //
  paragraphText: '#656666', //
  lpColors: {
    headerLp: '#004C7C',
    headerTextColor: '#fff',
    openingScreen: '#FFFFFF',
    infinityDataScreen: '#07203C',
    infinityDataText: '#FFFFFF', //
    infinityDataCircle: '#58C7DC', //
    infinityDataIcon: '#58C7DC', //
    whatIsScreen: '#FFFFFF',
    whatIsScreenTextColor: '#ffffff',
    whatIsIcon: '#1F6E95', //
    stepColor: '#1F6E95', //
    stepByStepScreen: '#FFFFFF',
    faqScreen: '#F3F8FF',
    footerLp: '#1A2E4E',
    simulatorButton: '#00A717', //
    color1: '#00A717', //green1
    color2: '#007A10', //green2
    color3: '#07203C', //blue1
    color4: '#1A2E4E', //blue2
    color5: '#2B4E85', //blue3
    color6: '#F3F8FF', //light gray
  },
};
